import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Script } from "gatsby"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 60 * 1000, // 1h
      cacheTime: 4 * 60 * 60 * 1000, // 24 godziny
    },
  },
})

const RootElement = ({ children }) => {
  return (
    <>
      <Script
        key={"gatsby-cookie-consent-mode-cookiebot"}
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="1d920cb8-d500-4561-b66a-20493fe63aff"
        data-blockingmode="auto"
        type="text/javascript"
      ></Script>
      <Script
        async
        strategy="idle"
        src="https://www.googletagmanager.com/gtag/js?id=G-6TNEN12MN6"
      ></Script>
      <Script
        key={"setup-gcm"}
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default',
          {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'ad_personalization': 'denied',
          'ad_user_data': 'denied',
          'personalization_storage': 'denied',
          'security_storage': 'granted',
          'functionality_storage': 'denied',
          'wait_for_update': 500,
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", false);
        `,
        }}
      ></Script>
      <Script
        key={"setup-g"}
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6TNEN12MN6', {'allow_enhanced_conversions':true});
        `,
        }}
      ></Script>
      <Script
        strategy="idle"
        async
        data-obct
        type="text/javascript"
        key={`gatsby-ms-bing`}
        dangerouslySetInnerHTML={{
          __html: ` (function(w,d,t,r,u)
          {
            var f,n,i;
            w[u]=w[u]||[],f=function()
            {
              var o={ti:"97081979", enableAutoSpaTracking: true};
              o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
            },
            n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
            {
              var s=this.readyState;
              s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
            },
            i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
          })
          (window,document,"script","//bat.bing.com/bat.js","uetq");`,
        }}
      />
      {/* <Script
        strategy="idle"
        data-obct
        type="text/javascript"
        key={`gatsby-hotjar`}
        dangerouslySetInnerHTML={{
          __html: ` (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5067723,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      ></Script> */}
      <Script
        data-obct
        async
        strategy="idle"
        type="text/javascript"
        key={`tiktok-pixel`}
        dangerouslySetInnerHTML={{
          __html: `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


          ttq.load('CQRTS5BC77U3LH4LN9E0');
          ttq.page();
        }(window, document, 'ttq');`,
        }}
      ></Script>
      <Script
        key={`onetads-pixel2`}
        strategy="idle"
        async
        dangerouslySetInnerHTML={{
          __html: `dlApi = {
        cmd: []
    };`,
        }}
      ></Script>

      <Script
        key={`onetads-pixel`}
        strategy="idle"
        async
        src="https://lib.onet.pl/s.csr/build/dlApi/minit.boot.min.js"
      ></Script>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </>
  )
}

export default RootElement
